import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

// Import components
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBanner from "../components/title-banner"
import DateSelectorDesktop from "../components/date-selector-desktop"

const Presentations = () => {
  const data = useStaticQuery(PRESENTATIONS)
  const imageData = data?.allImageSharp?.nodes || []
  return (
    <Layout>
      <Seo title="Home" />
      <TitleBanner imageData={imageData}>Presentations</TitleBanner>
      <DateSelectorDesktop
        tabs={[[2021, 2020, 2019, 2018, 2017, 2016]]}
      ></DateSelectorDesktop>
    </Layout>
  )
}

export default Presentations

const PRESENTATIONS = graphql`
  {
    allImageSharp(
      filter: {
        fixed: {
          originalName: {
            in: [
              "banner-image-integrated-reports.png"
              "banner-image-integrated-reports-mobile.png"
            ]
          }
        }
      }
      sort: { fields: fixed___originalName, order: DESC }
    ) {
      nodes {
        gatsbyImageData
      }
    }
  }
`
