import React, { useState } from "react"
import styled from "styled-components"

import PdfBlock from "../components/pdf-block"

const DateSelectorDesktop = ({ tabs = {}, types = [], content = [] }) => {
  const [selectedTabs, setSelectedTabs] = useState(
    Array.apply(null, Array(tabs.length)).map(() => {
      return 0
    })
  )

  const [selectedType, setSelectedType] = useState(0)

  const handleTabSelect = (index, i) => {
    setSelectedTabs(prev => [
      ...prev.slice(0, index),
      i,
      ...prev.slice(index + 1),
    ])
  }

  const handleTypeSelect = i => {
    setSelectedType(i)
  }

  return (
    <Container>
      <TabSection>
        {Object.values(tabs).map((value, index) => (
          <TabRow key={index}>
            {value.map((v, i) => (
              <Tab
                key={i}
                className={selectedTabs[index] == i ? "selected" : ""}
                onClick={() => handleTabSelect(index, i)}
              >
                {v}
              </Tab>
            ))}
          </TabRow>
        ))}
      </TabSection>
      <TypeSection>
        {types.map((value, index) => {
          return (
            <Type
              key={index}
              className={index == selectedType ? "selected" : ""}
              onClick={() => handleTypeSelect(index)}
            >
              {value}
            </Type>
          )
        })}
      </TypeSection>
      <ContentSection>
        {content.map((value, index) =>
          types.length == 0 || value.Type == types[selectedType] ? (
            <PdfBlock
              key={index}
              url={value.PDF_File.File.localFile.publicURL}
            ></PdfBlock>
          ) : null
        )}
      </ContentSection>
    </Container>
  )
}

export default DateSelectorDesktop

// ====================
//        STYLES
// ====================

const Container = styled.div`
  width: 1260px;
  height: fit-content;
  display: flex;
  flex-direction: column;
`
const TabSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: white;
`

const ContentSection = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  justify-content: space-between;
`
const TabRow = styled.div`
  display: flex;
  height: fit-content;
  justify-content: space-between;
  padding: 0 10px;
`
const Tab = styled.div`
  background-color: #f5f5f5;
  height: 80px;
  display: flex;
  flex-grow: 1;
  margin: 10px;
  font: normal normal normal 21px/28px Open Sans;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-out;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: rgb(221, 221, 221);
  }

  &.selected {
    background-color: #007cc5;
    color: white;
  }
`

const TypeSection = styled.div`
  display: flex;
  height: fit-content;
  justify-content: space-between;
  margin-top: 100px;
`

const Type = styled.div`
  font: normal normal normal 21px/28px Open Sans;
  cursor: pointer;

  &.selected {
    font: normal normal bold 28px/38px Open Sans;
    color: #007cc5;
    text-decoration: underline;
  }
`
const TempPDFBlock = styled.div`
  height: 380px;
  width: 299px;
  background-color: yellow;
  margin-bottom: 20px;
`
