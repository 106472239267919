import React, { useContext, memo } from "react"
import styled from "styled-components"

const PdfBlock = ({ url = {} }) => {
  return (
    <EmbedStyled src={`${url}#toolbar=0&navpanes=0&scrollbar=0`}></EmbedStyled>
  )
}
export default PdfBlock

// ====================
//        STYLES
// ====================

const EmbedStyled = styled.iframe`
  width: 295px;
  height: 379px;
  overflow-y: hidden;
  border: none;
`
