import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import Arrow from "../images/Icon feather-arrow-down.svg"

const TitleBanner = ({ imageData, scrollRef, children }) => {
  const desktopImage = imageData[0].gatsbyImageData
  const mobileImage = imageData[1].gatsbyImageData

  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  const handleScroll = () => {
    window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
  }

  return (
    <BannerStyled>
      <TitleStyled>{children}</TitleStyled>
      <GatsbyImage
        alt="Banner Image"
        className="banner-image"
        image={windowWidth > 960 ? desktopImage : mobileImage}
        objectFit="cover"
      ></GatsbyImage>
      <ArrowIcon onClick={handleScroll}>
        <img src={Arrow}></img>
      </ArrowIcon>
    </BannerStyled>
  )
}

export default TitleBanner

// ====================
//        STYLES
// ====================

const BannerStyled = styled.div`
  width: 100%;
  height: 450px;
  background-color: #1a4584;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > .banner-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 0;

    picture {
      opacity: 0.5;
    }
  }

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    height: calc(100vh - 100px);
  }
`
const TitleStyled = styled.h1`
  font: normal normal bold 67px/92px Open Sans;
  color: white;
  text-align: center;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    font: normal normal bold 38px/52px Open Sans;
  }
`
const ArrowIcon = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: white;
  z-index: 1;
  position: absolute;
  border-radius: 1px;

  left: calc(50% - 25px);
  top: 80%;

  @media (max-width: ${({ theme }) => theme.tabletLarge}) {
    display: flex;
  }
`
